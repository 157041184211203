import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { QUESTION_GROUP_TYPE, QUESTION_SINGLE_TYPE } from "../../constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PageSpinner from "../../Component/PageSpinner";
import "react-toastify/dist/ReactToastify.css";
import { AssessmentSingle } from "../../Component/AssessmentSingle";
import { AssessmentTypeB } from "../../Component/AssessmentTypeB";
import BtnSpinner from "../../Component/BtnSpinner";
import {
  ClosedTabPopup,
  FinishedTestPopup,
  NextAssessmentPopup,
  TimeUpPopup,
} from "../../Component/AssessmentPopup";
import PopupContainer from "../../Component/PopUp";
import { AssessmentBlank } from "../../Component/AssessmentBlank";
import { AssessmentMultiple } from "../../Component/AssessmentMultiple";
import { AssessmentMultipleAnswer } from "../../Component/AssessmentMultipleAnswer";
import { AssessmentSort } from "../../Component/AssessmentSort";
import { AssessmentGroup } from "../../Component/AssessmentGroup";
import { QuestionContext } from "../../Contexts/QuestionContext";
import ForceSubmitAssessmentPopup from "../../Component/AssessmentPopup/ForceSubmitAssessmentPopup";
import {
  setTimerDeducation,
  setQuestionTimeOver,
  setTestTimeOver,
  setIncreasePinaltiesViewport,
  setIncreasePinaltiesTab,
  setClearPinalties,
  setIncreasePinaltiesScreenBlur,
  setIncreasePinaltiesMouse,
  setDecreasePinaltiesMouse,
  setDecreasePinaltiesScreenBlur,
  setIncreasePinaltiesReloadPage,
  setIncreasePinaltiesNotFullscreen,
  setActive,
  setClearAll,
  setActiveTestCurrent,
  setActiveTestQuestion,
  setActiveTest,
} from "../../features/assessment/assessmentSlice";
import TimeQuestionUpPopup from "../../Component/AssessmentPopup/TimeQuestionUpPopup";
import AssessmentContentJson from "../../Component/AssessmentContentJson";
import BeforeUnload from "../../Component/BeforeUnload";
import { documentTitle, isMobileUserAgent, isPrime } from "../../helpers";
import html2canvas from "html2canvas";
import config from "../../config";
import ViewportChangedPopup from "../../Component/AssessmentPopup/ViewportChangedPopup";
import Compressor from "compressorjs";
import ScreenFocusChangePopup from "../../Component/AssessmentPopup/ScreenFocusChangePopup";
import MouseOutScreen from "../../Component/AssessmentPopup/MouseOutScreen";
import {
  progressFinish,
  progressFinishGroup,
  progressPinalties,
  progressSubmit,
  progressSubmitGroup,
} from "../../api";
import AssessmentProgressBar from "../../Component/AssessmentProgressBar";
import CameraCapture from "../../Component/Experimental/CameraCapture";
import { ArrowDownIcon } from "@heroicons/react/24/outline";

const AssessmentTest = () => {
  let navigate = useNavigate();
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const noDevice = sessionStorage.getItem("no-device");
  const contentContainerReff = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [test, setTest] = useState({
    id: "",
    name: "",
    image: "",
    description: "",
    time_type: "",
    times: "",
    total_question: 0,
    first_question: null,
    assessment: {
      id: "",
      name: "",
      image: "",
      description: "",
    },
  });
  const dispatch = useDispatch();
  const { question_id } = useParams();
  const [progressSubmitting, setProgressSubmitting] = useState(false);
  const [initScreen, setInitScreen] = useState(true);
  const [capturedImage, setCapturedImage] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [nextTest, setNextTest] = useState(null);
  const [closedTab, setClosedTab] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [getting2, setGetting2] = useState(true);
  const [getting, setGetting] = useState(true);
  const [current, setCurrent] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [, setMediaSource] = useState(null);
  const [next_question, setNextQuestion] = useState(null);
  const [, setPrevQuestion] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [questionType, setQuestionType] = useState("");
  const [, setCandidateAssessmentId] = useState("");
  const [iterateCapture, setIterateCapture] = useState(null);
  const [iterateCaptureCount, setIterateCaptureCount] = useState(0);
  // const [questionMultiple, setQuestionMultiple] = useState(null);
  const [answers, setAnswers] = useState(new Array());
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [hasOverflow, setHasOverflow] = useState(false);
  const [changeViewport, setChangeViewport] = useState(false);
  const [mouseOutScreen, setMouseOutScreen] = useState(false);
  const [voiceRecording, setVoiceRecording] = useState(false);
  const [voiceBlob, setVoiceBlob] = useState(null);
  const [mediaVoiceRecorder, setMediaVoiceRecorder] = useState(null);
  const [pageBlur, setPageBlur] = useState(false);
  // const [timeQuestionIsUp, setTimeQuestionIsUp] = useState(false);
  const [answerDisabled, setAnswerDisabled] = useState(false);
  const { question } = useContext(QuestionContext);
  const { loadingQuestion } = useContext(QuestionContext);
  const { assessment } = useContext(QuestionContext);
  const { assessmentLoading } = useContext(QuestionContext);
  const { candidateTestId } = useContext(QuestionContext);
  const { forceFinish } = useContext(QuestionContext);
  const { questionIsOver, testIsOver } = useSelector(
    (state) => state.assessment.timer
  );
  const { token } = useSelector((state) => state.assessment);

  const {
    viewportChange,
    tabChange,
    screenNotFocused,
    mouseNotFocused,
    reloadPage,
    notFullscreen,
  } = useSelector((state) => state.assessment.pinalties);

  useEffect(() => {
    handleUpdatePinalty();
  }, [
    viewportChange,
    tabChange,
    screenNotFocused,
    mouseNotFocused,
    reloadPage,
    notFullscreen,
  ]);

  const handleUpdatePinalty = async () => {
    const pinalties = {
      pinalties: {
        viewportChange,
        tabChange,
        screenNotFocused,
        mouseNotFocused,
        reloadPage,
        notFullscreen,
      },
    };
    await progressPinalties(token, pinalties);
  };
  const setAnswer = (answer) => {
    let tempAnswers = answers;
    let available = tempAnswers.findIndex((ans) => ans.id == answer.id);

    answer.candidate_assessment_id = assessment.candidate_assessment_id;
    answer.candidate_job_id = assessment.candidate_job_id;
    answer.candidate_test_id = candidateTestId;
    answer.question_id = answer.id;
    answer.options = JSON.stringify(answer.options);

    if (available < 0) {
      // alert("TRIGER")
      tempAnswers.push(answer);
      setCurrent(current + 1);
    } else {
      tempAnswers[available] = answer;
    }
    // ENABALE NEXT
    if (answer.answer == "" || answer.answer == null) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
    setAnswers(tempAnswers);
  };

  const setGroupAnswer = (answer, increment) => {
    let tempAnswers = answers;
    let available = tempAnswers.findIndex((ans) => ans.id == answer.id);
    setCurrent(current + increment);

    answer.candidate_assessment_id = assessment.candidate_assessment_id;
    answer.candidate_job_id = assessment.candidate_job_id;
    answer.candidate_test_id = candidateTestId;
    answer.group_id = answer.id;
    answer.options = JSON.stringify(answer.options);
    if (available < 0) {
      tempAnswers.push(answer);
    } else {
      tempAnswers[available] = answer;
    }
    const isEveryQuestionAnswered = answer.questions.every((question) =>
      question.hasOwnProperty("answer")
    );
    if (isEveryQuestionAnswered) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
    setAnswers(tempAnswers);
  };

  const submit = async () => {
    setCanNext(false);
    setSubmitting(true);
    let payloads = [];
    answers.forEach((answer) => {
      if (answer.variant == QUESTION_SINGLE_TYPE) {
        const question = {
          candidate_assessment_id: answer.candidate_assessment_id,
          candidate_job_id: answer.candidate_job_id,
          candidate_test_id: answer.candidate_test_id,
          question_id: answer.id,
          answer: answer.answer?.toString(),
        };
        payloads = payloads.concat(question);
      } else if (answer.variant == QUESTION_GROUP_TYPE) {
        const questions = answer.questions.map((question) => {
          return {
            candidate_assessment_id: answer.candidate_assessment_id,
            candidate_job_id: answer.candidate_job_id,
            candidate_test_id: answer.candidate_test_id,
            question_id: question.id,
            answer: question.answer?.toString(),
          };
        });
        payloads = payloads.concat(questions);
      }
    });

    clearInterval(iterateCapture);
    setIterateCapture(null);

    // STORE & UPDATE MILESTONE
    const finished = await handleProgressSubmit(true);
    if (!finished.success) {
      toast.error(finished.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    // STORE ANSWERS
    const submitted = await submitQuestion(payloads);
    if (submitted) {
      dispatch(setClearAll());
      sessionStorage.removeItem("latest_timer");
      sessionStorage.removeItem("no-device");
      const answers_session = JSON.parse(sessionStorage.getItem("answers"));
      if (answers_session) {
        sessionStorage.removeItem("answers");
      }
      // STORE DEVICE
      await submitDevice();
    }
    setSubmitting(false);
  };

  const handleQuestionIsOver = () => {
    dispatch(setQuestionTimeOver(false));
    if (current >= test.total_question) {
      submit();
    } else {
      getNextQuestion(next_question);
    }
  };

  const handleTestIsOver = () => {
    dispatch(setTestTimeOver(false));
    submit();
  };

  const submitQuestion = async (payloads = []) => {
    const urlSubmit = forceFinish
      ? "/api/assessment/test/store-force/true"
      : "/api/assessment/test";
    const response = await axios
      .post(urlSubmit, JSON.stringify(payloads), {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setSubmitted(true);
          setNextTest(result.data.next);
          return result;
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        return false;
      });

    return response;
  };

  const submitDevice = async () => {
    const pinalties = [
      {
        type: "viewport_change",
        total: viewportChange,
        description: "Change viewport / window size during test",
      },
      {
        type: "tab_change",
        total: tabChange,
        description: "Closed / Change tab during test",
      },
      {
        type: "mouse_out_screen",
        total: mouseNotFocused,
        description: "Mouse out of screen during test",
      },
      {
        type: "screen_not_focused",
        total: screenNotFocused,
        description: "Page not focused during test",
      },
      {
        type: "reload_page",
        total: reloadPage,
        description: "Reload page during test",
      },
      {
        type: "not_fullscreen",
        total: notFullscreen,
        description: "Not fullscreen during test",
      },
    ];

    const payloads = {
      candidate_test_id: candidateTestId,
      device_user: "Desktop;" + navigator.userAgent,
      location: sessionStorage.getItem("location"),
      webcam_enable: true,
      microphone_enable: true,
      mouse_in_window: mouseNotFocused > 0 ? false : true,
      fullscreen_mode: notFullscreen > 0 ? false : true,
      pinalty: pinalties,
    };

    const response = await axios
      .post("/api/assessment/capture-device", JSON.stringify(payloads), {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        return false;
      });

    dispatch(setClearAll());
    // dispatch(setActive(null));
    return response;
  };

  const getTest = async () => {};

  const scrollToTop = () => {
    // Access the div element using the ref and set its scrollTop property to 0
    if (contentContainerReff.current) {
      contentContainerReff.current.scrollTop = 0;
    }
  };

  const scrollToBottom = () => {
    // Access the div element using the ref and set its scrollTop property to 0
    if (contentContainerReff.current) {
      contentContainerReff.current.scrollTo({
        top: contentContainerReff.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const getNextQuestion = async (next = question_id) => {
    setSubmitting(true);
    setCanNext(false);
    const submitAnswer = await handleProgressSubmit();

    if (submitAnswer.success) {
      setSubmitting(false);
      sessionStorage.setItem("answers", JSON.stringify(answers));
      scrollToTop();
      if (sessionStorage.getItem("latest_timer")) {
        sessionStorage.removeItem("latest_timer");
      }
      navigate(`/assessment/test/${candidateTestId}/q/${next}`, {
        replace: true,
      });
    } else {
      setSubmitting(false);
      setCanNext(true);
      toast.error(submitAnswer.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleProgressSubmit = async (finished = false) => {
    const current_answer = answers[answers.length - 1];
    let submitAnswer = null;
    if (current_answer.variant == "group") {
      const payload = current_answer.questions.map((answer) => {
        return {
          question_id: answer.question_id,
          group_id: current_answer.group_id,
          answer: answer.answer,
          order: answer.order,
          timer_at: sessionStorage.getItem("latest_timer"),
        };
      });
      if (finished) {
        submitAnswer = await progressFinishGroup(token, payload);
      } else {
        submitAnswer = await progressSubmitGroup(token, payload);
      }
    } else {
      const payload = {
        question_id: current_answer.question_id,
        answer: current_answer.answer,
        order: current_answer.order,
        timer_at: sessionStorage.getItem("latest_timer"),
      };
      if (finished) {
        submitAnswer = await progressFinish(token, payload);
      } else {
        submitAnswer = await progressSubmit(token, payload);
      }
    }

    return submitAnswer;
  };

  const handleAlertClosedTab = (confirm = false) => {
    setClosedTab(confirm);
  };

  const handleAlertViewport = (confirm = false) => {
    setChangeViewport(confirm);
  };

  const handleAlertScreenFocus = (confirm = false) => {
    setPageBlur(confirm);
  };

  const handleMouseActivity = (confirm = false) => {
    setMouseOutScreen(confirm);
  };

  const handleFinishedTestPopup = (confirm = false) => {
    setClosedTab(confirm);
    setSubmitted(false);
    window.location.replace(`/assessment`);
  };

  const createStream = async () => {
    const devices = JSON.parse(sessionStorage.getItem("devices"));
    let constraints = {
      audio: {
        deviceId: devices.mic,
      },
      video: {
        deviceId: devices.camera,
      },
    };

    var mediaSource = new MediaStream();
    mediaSource.addEventListener("sourceopen", handleSourceOpen, false);
    setMediaSource(mediaSource);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(successCallback, errorCallback);
  };

  const startVoiceRecording = async () => {
    const devices = JSON.parse(sessionStorage.getItem("devices"));
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: devices.mic,
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      },
    });
    const recorder = new MediaRecorder(stream, {
      mimeType: "audio/webm;codecs=opus",
      audioBitsPerSecond: 64000,
    });
    recorder.start();
    // recorder.addEventListener("error", (event) => {
    //   console.log("Recording error: ", event.error);
    // });

    recorder.addEventListener("dataavailable", (e) => {
      setVoiceBlob(e.data);
      const audioUrl = URL.createObjectURL(e.data);
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = "recorded_audio.webm"; // or .wav, .mp3, etc. depending on the format
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

    setVoiceRecording(true);
    setMediaVoiceRecorder(recorder);
  };
  const stopVoiceRecording = () => {
    mediaVoiceRecorder.stop();
  };

  const testRef = useRef();
  const captureScreen = () => {
    const element = document.getElementById("root");

    html2canvas(element).then((canvas) => {
      canvas.toBlob(
        async (blob) => {
          // let url = URL.createObjectURL(blob);
          let formData = new FormData();
          formData.append("type", "image_screen");
          formData.append("candidate_test_id", candidateTestId);
          formData.append("file", blob);
          await axios.post(`/api/assessment/capture`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          });
        },
        "image/webp",
        0.3
      );
    });
  };

  const handleSourceOpen = () => {};

  const errorCallback = (e) => {
    toast.error("CALLBACK ERROR: ", e.message, {
      toastId: "login-success-toast",
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const successCallback = (stream) => {
    setMediaStream(stream);
    captureImage(stream);
  };

  const captureImage = async (stream) => {
    try {
      // let capturedFrame = null;
      // videoRef.current.srcObject = stream;
      // if ("ImageCapture" in window) {
      //   const track = stream.getVideoTracks()[0];
      //   const imageCapture = new ImageCapture(track);
      //   capturedFrame = await imageCapture.takePhoto();
      //   // const dataUrl = await blobToDataURL(capturedFrame);
      // } else {
      //   const width = videoRef.current.videoWidth;
      //   const height = videoRef.current.videoHeight;
      //   canvasRef.current.width = width;
      //   canvasRef.current.height = height;
      //   const context = canvasRef.current.getContext("2d");
      //   context.drawImage(videoRef.current, 0, 0, width, height);
      //   const imageDataUrl = canvasRef.current.toDataURL("image/png");
      //   capturedFrame = dataURLToBlob(imageDataUrl);
      // }
      // console.log("capturedFrame", capturedFrame);
      // Convert the captured frame Blob to a File object
      // const capturedFile = await new Promise((resolve) => {
      //   new Compressor(capturedFrame, {
      //     quality: 0.8,
      //     maxWidth: 720,
      //     minHeight: 720,
      //     mimeType: "image/webp",
      //     success(result) {
      //       resolve(result);
      //     },
      //   });
      // });
      // let formData = new FormData();
      // formData.append("type", "image");
      // formData.append("candidate_test_id", candidateTestId);
      // formData.append("file", capturedFile);
      // await axios
      //   .post(`/api/assessment/capture`, formData, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${user.token}`,
      //     },
      //   })
      //   .then(() => {
      //     // const result = response.data;
      //   })
      //   .catch((e) => {
      //     toast.error(e.message, {
      //       toastId: "login-success-toast",
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //   });
      // stream.getTracks().forEach((track) => {
      //   track.stop();
      // });
    } catch (error) {
      toast.error("Error capturing image: ", error.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const sendImage = async (blob) => {
    // console.log("blob", blob);
    // const capturedFile = await new Promise((resolve) => {
    //   new Compressor(blob, {
    //     quality: 0.8,
    //     maxWidth: 720,
    //     minHeight: 720,
    //     mimeType: "image/webp",
    //     success(result) {
    //       resolve(result);
    //     },
    //   });
    // });
    let formData = new FormData();
    formData.append("type", "image");
    formData.append("candidate_test_id", candidateTestId);
    formData.append("file", blob);
    await axios
      .post(`/api/assessment/capture`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        // const result = response.data;
      })
      .catch((e) => {
        toast.error(e.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });

    // if(isMobileUserAgent()) {

    // }
    mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    setMediaStream(null);
  };

  const handleNextTest = async () => {
    sessionStorage.removeItem("latest_timer");
    window.location.replace(`/assessment/test/${nextTest.id}/preparation`);
  };

  const handleFullScreen = () => {
    try {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      setInitScreen(false);
    } catch (error) {}
  };

  const handleSetActive = () => {
    dispatch(setActive(candidateTestId));
  };

  const checkOverflow = () => {
    const container = contentContainerReff.current;
    if (container) {
      const isOverflowing = container.scrollHeight > container.clientHeight;
      setHasOverflow(isOverflowing);
    }
  };

  useEffect(() => {
    if (forceFinish) {
      submit();
    }
  }, [forceFinish]);

  useEffect(() => {
    if (question) {
      if (question.variant == "group") {
        // setCurrent(question.real_order);
        // if (current <= 0) {
        // setCurrent(0);
        // }
      } else {
        // setCurrent(question.real_order);
      }

      setNextQuestion(question.next_question);
      setPrevQuestion(question.prev_question);
      setQuestionType(question.type);
      setQuestions(question);
    }

    if (assessment) {
      setCandidateAssessmentId(assessment.candidate_assessment_id);
      setTest(assessment.company_assessment_test.assessment_test);
    }
  });

  useEffect(() => {
    if (question) {
      // if (question.variant == "group") {
      setCurrent(question.real_order - 1);

      setTimeout(checkOverflow, 500);
      window.addEventListener("resize", checkOverflow);
      return () => {
        window.removeEventListener("resize", checkOverflow);
      };
      // }
    }
  }, [question]);

  useEffect(() => {}, [current]);

  useEffect(() => {
    handleSetActive();
    documentTitle("Assessment Test");
    handleFullScreen();
  }, []);

  useEffect(() => {
    if (loadingQuestion) {
      setAnswerDisabled(true);
    } else {
      setAnswerDisabled(false);
    }
  }, [loadingQuestion]);

  useEffect(() => {
    if (questionIsOver) {
      const answer = { ...question, answer: null };
      setAnswer(answer);
    }
  }, [questionIsOver]);

  useEffect(() => {
    if (testIsOver) {
      // const answer = { ...question, answer: null };
      // setAnswer(answer);
    }
  }, [testIsOver]);

  useEffect(() => {
    const handleClosedTab = () => {
      if (document.hidden) {
        setClosedTab(true);
        dispatch(setTimerDeducation(30));
        dispatch(setIncreasePinaltiesTab());
        setTimeout(() => {
          dispatch(setTimerDeducation(1));
        }, 1000);
      }
    };

    const handleResize = () => {
      if (!initScreen) {
        setChangeViewport(true);
        dispatch(setTimerDeducation(30));
        dispatch(setIncreasePinaltiesViewport());
        setTimeout(() => {
          dispatch(setTimerDeducation(1));
        }, 1000);
      }
    };

    const handleFocus = () => {};

    const handleBlur = () => {
      if (document.hidden || closedTab) return;
      setPageBlur(true);
      dispatch(setTimerDeducation(30));
      dispatch(setIncreasePinaltiesScreenBlur());
      dispatch(setIncreasePinaltiesMouse());
      setTimeout(() => {
        dispatch(setTimerDeducation(1));
      }, 1000);
    };

    const handleUnload = () => {
      dispatch(setIncreasePinaltiesReloadPage());
    };

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        dispatch(setIncreasePinaltiesNotFullscreen());
      }
    };

    const handleMousemove = (e) => {
      var xPos = e.clientX;
      var yPos = e.clientY;
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    window.addEventListener("mousemove", handleMousemove);
    window.addEventListener("visibilitychange", handleClosedTab);
    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("fullscreenchange", handleFullscreenChange);
    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("mousemove", handleMousemove);
      window.removeEventListener("visibilitychange", handleClosedTab);
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (closedTab) {
      if (pageBlur) {
        dispatch(setDecreasePinaltiesMouse());
        dispatch(setDecreasePinaltiesScreenBlur());
        setPageBlur(false);
      }
    }
  }, [closedTab, pageBlur]);

  useEffect(() => {
    async function get() {
      await getTest();
      // await getQuestion();
      setGetting(false);
      setGetting2(false);
    }
    get();
  }, []);

  // HANDLE IF REFRESH
  useEffect(() => {
    const answers = JSON.parse(sessionStorage.getItem("answers"));
    if (answers) {
      const lastOrder = Math.max(
        ...answers.map((answer) => {
          if (answer.variant == "group") {
            return Math.max(
              ...answer.questions.map((question) => question.order)
            );
          } else {
            return answer.order;
          }
        })
      );
      setAnswers(answers);
      // if (question?.variant == "group") {
      setCurrent(lastOrder);
      // }
    }
  }, []);

  useEffect(() => {}, [candidateTestId]);

  useEffect(() => {
    if (!assessmentLoading && !loadingQuestion) {
      if (noDevice == "false") {
        if (config.APP_ENV == "production") {
          if (current == 0) {
            setTimeout(() => {
              createStream();
            }, 1000);
          } else {
            if (isPrime(current)) {
              setTimeout(() => {
                createStream();
              }, 1000);
            }
          }
        } else {
          if (current == 0) {
            setTimeout(() => {
              createStream();
            }, 1000);
          }
        }

        if (current == 0) {
          setTimeout(() => {
            captureScreen();
          }, 1000);
        } else {
          if (isPrime(current)) {
            setTimeout(() => {
              captureScreen();
            }, 1000);
          }
        }
      }
    }
  }, [assessmentLoading, loadingQuestion]);

  useEffect(() => {
    if (iterateCaptureCount > 10) {
      if (noDevice == "false") {
        clearInterval(iterateCapture);
        setIterateCapture(null);
      }
    }
  }, [iterateCaptureCount]);

  useEffect(() => {
    dispatch(setActiveTestCurrent(current));
  }, [current]);

  useEffect(() => {
    dispatch(setActiveTestQuestion(question));
  }, [question]);

  useEffect(() => {
    dispatch(setActiveTest(test));
  }, [test]);

  return (
    <>
      <BeforeUnload />
      <ToastContainer />
      <CameraCapture
        stream={mediaStream}
        onCaptured={(blob) => {
          sendImage(blob);
        }}
      />
      <div
        className="bg-white overflow-y-auto mdb:rounded-lg shadow w-full mb-[40px] h-full flex flex-col rounded-t-[18px] "
        ref={testRef}
      >
        <div className="rounded-t-lg flex overflow-y-hidden">
          <div className="mdb:px-6 mdb:py-5 px-1 py-2 flex flex-col flex-1 md:overflow-y-auto overflow-y-scroll">
            {hasOverflow && (
              <button
                className="fixed bottom-[100px] right-[20px] bg-[#C5C5C59D] p-1 rounded-md"
                onClick={() => {
                  scrollToBottom();
                }}
              >
                <ArrowDownIcon className="h-6 w-6 text-[#1B77EEFF] text-blue" />
              </button>
            )}

            {getting ? (
              <PageSpinner />
            ) : (
              <>
                {/* <div className="h-[50px] md:h-[70px] flex justify-between mb-2 border-b border-line-grey-1 flex-col-reverse  md:flex-row w-full">
                  <div className="mt-2 md:mt-0 w-full">
                    <div className="w-full h-[6px] md:h-[12px] bg-white-1 rounded-full mt-2">
                      <div
                        className="h-full text-center text-xs text-white bg-main-violate rounded-full"
                        style={{
                          width: `${
                            ((question?.variant == "group"
                              ? current
                              : current) /
                              test.total_question) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div className="flex mt-2 mb-4">
                      <p className="font-semibold text-gray-700 text-[14px] leading-[140%] text-left">
                        Question :{" "}
                        {(
                          ((question?.variant == "group" ? current : current) /
                            test.total_question) *
                          100
                        ).toFixed(0)}
                        %
                      </p>
                      <p className="text-gray-400 text-[14px] leading-[140%] text-left ml-[4px]">
                        ({question?.variant == "group" ? current : current}/
                        {test.total_question} Completed)
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="md:px-0 px-2">
                  <AssessmentProgressBar />
                </div> */}

                {getting2 ? (
                  <PageSpinner />
                ) : (
                  <div
                    ref={contentContainerReff}
                    className="flex-1 overflow-y-auto"
                  >
                    {assessmentLoading || answerDisabled ? (
                      <PageSpinner padding={"20px"} />
                    ) : (
                      <div className="flex items-center justify-center flex-col md:px-0 px-2">
                        {questions && (
                          <>
                            {questions.context &&
                              questions.variant == "single" && (
                                <div className="w-full">
                                  <div className="w-full mdb:px-5 px-0 mt-0 mdb:mb-0 mb-2 font-medium py-2 md:py-4 ">
                                    {question.kind === "text" && (
                                      <>
                                        <p className="text-gray-900 leading-[180%] md:text-base text-base md:text-sm font-semibold">
                                          {question.content}
                                        </p>
                                      </>
                                    )}
                                    {question.kind === "json" && (
                                      <>
                                        {/* <ContentJson content={questions.content} /> */}
                                        <AssessmentContentJson
                                          question={questions.content}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <hr className="my-2" />
                                </div>
                              )}
                            {questions.context &&
                              questions.variant == "group" && (
                                <div className="w-full">
                                  <div className="w-full mdb:px-5 px-2 mdb:mt-5 mt-2">
                                    {question.kind === "text" && (
                                      <>
                                        <p className="text-gray-900 leading-[180%]">
                                          {question.content}
                                        </p>
                                      </>
                                    )}
                                    {question.kind === "json" && (
                                      <>
                                        {/* <ContentJson content={questions.content} /> */}
                                        <AssessmentContentJson
                                          question={questions.content}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            {/* <AssessmentSingle question={questionSingle} /> */}
                            {(() => {
                              if (questions.variant == "single") {
                                if (questionType == 1) {
                                  return (
                                    <AssessmentSingle
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                    />
                                  );
                                } else if (questionType == 2) {
                                  return (
                                    <AssessmentTypeB
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                    />
                                  );
                                } else if (questionType == 3) {
                                  return (
                                    <AssessmentMultiple
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                    />
                                  );
                                } else if (questionType == 4) {
                                  return (
                                    <AssessmentMultipleAnswer
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                      isNext={true}
                                    />
                                  );
                                } else if (questionType == 5) {
                                  return (
                                    <AssessmentBlank
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                    />
                                  );
                                } else if (questionType == 6) {
                                  return (
                                    <AssessmentSort
                                      question={questions}
                                      answer={setAnswer}
                                      currentAnswer={answers}
                                    />
                                  );
                                }
                              } else if (questions.variant == "group") {
                                // if (questionType == 3) {
                                return (
                                  <AssessmentGroup
                                    question={questions}
                                    answer={setGroupAnswer}
                                    currentAnswer={answers}
                                  />
                                );
                                // }
                              }
                            })()}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {!getting2 && (
                  <div className="flex justify-center ">
                    {current == 1 ? (
                      <>
                        {/* <button
                          className="border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg mt-4"
                          type="submit"
                        >
                          Back
                        </button> */}
                        {current >= test.total_question ? (
                          <button
                            className="bg-main-violate w-[120px] md:w-[160px] px-4 text-[14px] md:text-[16px] leading-[22.4px] text-white font-semibold h-[42px] md:h-[46px] rounded-lg ml-0 md:ml-3 mt-2 md:mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={!canNext}
                            onClick={submit}
                          >
                            {submitting ? <BtnSpinner /> : "Submit"}
                          </button>
                        ) : (
                          <button
                            className="bg-main-violate w-[120px] md:w-[160px] px-4 text-[14px] md:text-[16px] leading-[22.4px] text-white font-semibold h-[42px] md:h-[46px] rounded-lg ml-0 md:ml-3 mt-2 md:mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={!canNext}
                            onClick={() => getNextQuestion(next_question)}
                          >
                            {submitting ? <BtnSpinner /> : "Next"}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <button
                                  className="border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg mt-4 hover:border-2 disabled:cursor-not-allowed"
                                  type="submit"
                                  onClick={(e) => getQuestion(prev_question)}
                                  disabled
                                >
                                  Back
                                </button> */}

                        {current >= test.total_question ? (
                          <button
                            className="bg-main-violate w-[120px] md:w-[160px] px-4 text-[14px] md:text-[16px] leading-[22.4px] text-white font-semibold h-[42px] md:h-[46px] rounded-lg ml-0 md:ml-3 mt-2 md:mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={!canNext}
                            onClick={submit}
                          >
                            {submitting ? <BtnSpinner /> : "Submit"}
                          </button>
                        ) : (
                          <button
                            className="bg-main-violate w-[120px] md:w-[160px] px-4 text-[14px] md:text-[16px] leading-[22.4px] text-white font-semibold h-[42px] md:h-[46px] rounded-lg ml-0 md:ml-3 mt-2 md:mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={!canNext}
                            onClick={() => getNextQuestion(next_question)}
                          >
                            {submitting ? <BtnSpinner /> : "Next"}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {questionIsOver ? (
        <>
          (
          <PopupContainer>
            <TimeQuestionUpPopup
              onClose={() => handleQuestionIsOver()}
              confirmLabel={
                current >= test.total_question ? "Submit" : "Continue"
              }
            />
          </PopupContainer>
          )
        </>
      ) : (
        ""
      )}
      {testIsOver ? (
        <>
          (
          <PopupContainer>
            <TimeUpPopup
              onClose={() => handleTestIsOver()}
              confirmLabel={
                current >= test.total_question ? "Submit" : "Continue"
              }
            />
          </PopupContainer>
          )
        </>
      ) : (
        ""
      )}
      {submitted ? (
        <PopupContainer>
          {forceFinish ? (
            <>
              <ForceSubmitAssessmentPopup
                onClose={() => handleFinishedTestPopup(false)}
              />
            </>
          ) : (
            <>
              {nextTest ? (
                <NextAssessmentPopup
                  onClose={() => handleFinishedTestPopup(false)}
                  onContinue={() => handleNextTest()}
                />
              ) : (
                <FinishedTestPopup
                  onClose={() => handleFinishedTestPopup(false)}
                />
              )}
            </>
          )}
        </PopupContainer>
      ) : (
        ""
      )}

      {/* PINALTIES */}
      {noDevice == "false" && (
        <>
          {closedTab && !submitted && (
            <>
              <PopupContainer>
                <ClosedTabPopup onClose={() => handleAlertClosedTab(false)} />
              </PopupContainer>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}

          {changeViewport && !submitted && (
            <>
              <PopupContainer>
                <ViewportChangedPopup
                  onClose={() => handleAlertViewport(false)}
                />
              </PopupContainer>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}

          {pageBlur && !submitted && (
            <>
              <PopupContainer>
                <ScreenFocusChangePopup
                  onClose={() => handleAlertScreenFocus(false)}
                />
              </PopupContainer>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}

          {mouseOutScreen && !submitted && (
            <>
              <PopupContainer>
                <MouseOutScreen onClose={() => handleMouseActivity(false)} />
              </PopupContainer>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AssessmentTest;
