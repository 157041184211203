import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { RssIcon } from "@heroicons/react/24/outline";
import CameraView from "../../Component/CameraView/CameraView";
import {
  VideoCameraIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";
import { documentTitle, isMobileUserAgent } from "../../helpers";
import DevicePermission from "../../Component/DevicePermission";

const Device = () => {
  let navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const noDevice = sessionStorage.getItem("no-device");

  const videoRef = useRef(null);
  const [grantedDevice, setGrantedDevice] = useState(false);
  const [, setVolume] = useState(0);
  const [cameras, setCameras] = useState([]);
  const [camera, setCamera] = useState(null);
  const [, setMics] = useState([]);
  const [mic, setMic] = useState(null);
  const [, setSpeakers] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const [mediaSource, setMediaSource] = useState(null);
  const [, setSourceBuffer] = useState(null);
  const [placeLocation, setPlaceLocation] = useState(null);
  const [connectionSpeed, setConnectionSpeed] = useState(null);
  const [stream, setStream] = useState(null);
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const { candidate_test_id } = useParams();

  const createStream = () => {
    try {
      if (stream) {
        setTimeout(() => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
          setStream(null);
        }, 2000);
      }
      setSpeaker(speaker ? speaker : "default");
      setMic(mic ? mic : "default");
      setCamera(camera ? camera : "default");
      let constraints = {
        audio: {
          deviceId: mic,
        },
        video: {
          deviceId: camera,
        },
      };

      var mediaSource = new MediaStream();
      mediaSource.addEventListener("sourceopen", handleSourceOpen, false);
      setMediaSource(mediaSource);
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(successCallback, errorCallback);
    } catch (error) {
      toast.error(error.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const successCallback = (stream) => {
    videoRef.current.srcObject = stream;
    setStream(stream);
    videoRef.current.play();
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyzer = audioContext.createAnalyser();
    source.connect(analyzer);
    analyzer.fftSize = 32; // Number of frequency bins for analysis
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const updateVolume = () => {
      analyzer.getByteFrequencyData(dataArray);
      const averageVolume =
        [...dataArray].reduce((a, b) => a + b, 0) / bufferLength;
      setVolume(averageVolume > 150 ? 150 : averageVolume);
      requestAnimationFrame(updateVolume);
    };
    updateVolume();
  };

  const errorCallback = (error) => {
    toast.error(error.message, {
      toastId: "login-success-toast",
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSourceOpen = () => {
    setSourceBuffer(mediaSource.addSourceBuffer("video/mp4"));
  };

  const handleContinue = () => {
    if (!noDevice) {
      sessionStorage.setItem(
        "devices",
        JSON.stringify({
          speaker: speaker != "default" ? speaker.deviceId : "default",
          camera: camera != "default" ? camera.deviceId : " default",
          mic: mic != "default" ? mic.deviceId : "default",
        })
      );
      sessionStorage.setItem("location", JSON.stringify(placeLocation));
    }

    if (noDevice) {
      navigate(
        `/assessment/test/${candidate_test_id}/preparation?no-device=true`
      );
    } else {
      navigate(`/assessment/test/${candidate_test_id}/preparation`);
    }
  };

  useEffect(() => {
    console.log("Ismobile ", isMobileUserAgent());
    documentTitle("Assessment Setup");
  }, []);
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  // check connection
  useEffect(() => {
    const checkConnectionSpeed = async () => {
      let fileSize = ""; // 5MB file size (adjust as needed)
      // const fileUrl = "https://source.unsplash.com/random?topics=nature"; // URL to a large file on your server
      const fileUrl = "https://speed.cloudflare.com/__down?bytes=5000000"; // URL to a large file on your server

      const startTime = new Date().getTime();

      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob(); // Read the response as a Blob
        const fileSize = blob.size; // File size in bytes

        const endTime = new Date().getTime();
        const timeDuration = (endTime - startTime) / 1000; // Time taken in seconds

        // Calculate speed
        const loadedBits = fileSize * 8;
        const speedInBps = (loadedBits / timeDuration).toFixed(2);
        const speedInKbps = (speedInBps / 1024).toFixed(2);
        const speedInMbps = (speedInKbps / 1024).toFixed(2);
        setConnectionSpeed(speedInMbps);
      } catch (error) {
        toast.error("Error fetching file:", error.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };

    setInterval(() => {
      checkConnectionSpeed();
    }, 5000);
  }, []);

  // Listing Devices
  useEffect(() => {
    // console.log(navigator.userAgent)
    console.log("noDevice", noDevice);
    if (noDevice == "false") {
      console.log("MASUK SINI 1");
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        setCameras(devices.filter((device) => device.kind === "videoinput"));
        setMics(devices.filter((device) => device.kind === "audioinput"));
        setSpeakers(devices.filter((device) => device.kind === "audiooutput"));
      });

      if (cameras) {
        createStream();
      }

      if ("geolocation" in navigator) {
        // Geolocation is available
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setPlaceLocation(latitude + "," + longitude);
          },
          function (error) {
            // Handle any errors that occur when trying to access the user's location
            toast.success(error.message, {
              toastId: "forgot-success-toast",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1250,
            });
          }
        );
      } else {
        // Geolocation is not available in this browser
        toast.success("Geolocation is not available in your browser.", {
          toastId: "forgot-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
        });
      }
    } else {
      console.log("MASUK SINI 2");
      // setGrantedDevice(true);
      // handleContinue();
      navigate(
        `/assessment/test/${candidate_test_id}/introduction?no-device=true`
      );
    }
  }, [noDevice]);

  useEffect(() => {
    console.log("camera", camera);
    console.log("mic", mic);
    console.log("speaker", speaker);
  }, [camera, mic, speaker]);

  const handleGrantAccess = () => {
    toast.success("Access Granted");
    setGrantedDevice(true);
  };
  return (
    <>
      <ToastContainer />
      {!grantedDevice && !noDevice && (
        <DevicePermission grantAccess={() => handleGrantAccess()} />
      )}

      <div className="bg-white rounded-lg shadow mdb:h-full sm:h-max h-full flex flex-col">
        <div className="card mb-6 hidden mdb:block">
          <div className="w-full py-6">
            <div className="flex">
              <div className="w-1/2">
                <div className="relative mb-2">
                  <div className="w-12 h-12 mx-auto bg-main-violate-100 rounded-full text-lg flex items-center justify-center">
                    <VideoCameraIcon
                      width={28}
                      className="text-main-violate-600"
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2">
                <div className="relative mb-2">
                  <div
                    className="absolute flex align-center items-center align-middle content-center"
                    style={{
                      width: "calc(100% - 2.5rem - 1rem)",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                      <div className="w-0 bg-main-violate-100 py-1 rounded"></div>
                    </div>
                  </div>
                  <div className="w-12 h-12 mx-auto bg-gray-100 rounded-full text-lg flex items-center justify-center">
                    <ClipboardDocumentListIcon
                      width={28}
                      className="text-gray-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-t-lg h-full">
          <div className="px-3 py-3 md:px-6 md:py-8 h-full">
            <div className="flex md:block flex-col justify-between h-full">
              <div>
                {!noDevice && (
                  <>
                    <h2 className="font-bold text-base md:text-xl mb-2 md:mb-4 ">
                      Camera Setup
                    </h2>
                    <p className="text-slate-600 text-sm md:text-base">
                      We use camera images to ensure fairness for everyone.
                    </p>
                    <p className="text-slate-600 mb-4 text-sm md:text-base">
                      Make sure that you are in front of your camera.
                    </p>
                    <CameraView
                      defaultCamera={camera}
                      defaultSpeaker={speaker}
                      defaultMic={mic}
                      selectedCamera={setCamera}
                      selectedMic={setMic}
                      selectedSpeaker={setSpeaker}
                    />
                  </>
                )}

                <hr className="my-2 md:my-6" />
                <div className="flex flex-col-reverse sm:flex-row mb-2">
                  <div className="flex text-sm font-bold">
                    <p>Connection Speed &nbsp;</p>
                    <p
                      className={
                        connectionSpeed > 0.5
                          ? `text-green-1`
                          : `text-danger-main`
                      }
                    >
                      {connectionSpeed} Mbps &nbsp;
                    </p>
                    <RssIcon
                      className={
                        connectionSpeed > 0.5
                          ? `w-[14px] text-green-1`
                          : `w-[14px] text-danger-main`
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-start sm:justify-end">
                {grantedDevice && (
                  <button
                    className="bg-main-violate w-full sm:w-[250px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg md:mt-4 hover:bg-violet-600"
                    type="submit"
                    onClick={() => handleContinue()}
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Device;
